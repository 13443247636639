import React, { useState, forwardRef, useImperativeHandle } from 'react';
import './Table.css';
import Chip from './Chip.js';
import _003ETH from './01BNB.png';
import _006ETH from './02BNB.png';
import _015ETH from './03BNB.png';
import _03ETH from './05BNB.png';
import _06ETH from './1BNB.png';

function Table(props, ref) {
  
  //REMOVE CHIP IMAGES
  const [tableHistory, setTableHistory] = useState([]);
  const [betTotal, setTotalBet] = useState(0);
  const [maxBets, setMaxBets] = useState(
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, // 0 - 9
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, // 10 - 19
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, // 20 - 29
    0, 0, 0, 0, 0, 0, 0, 0]); // 30 - 37
  //CREATE MAP FOR CHIP STATE
  const [chipState, setChipState] = useState({});
  const [chipHistory, setChipHistory] = useState([]);
  const [previousBets, setPreviousBets] = useState([]);
  const [previousChips, setPreviousChips] = useState({});
  const [previousMaxBets, setPreviousMaxBets] = useState([]);
  const [previousTotalBet, setPreviousTotalBet] = useState(0);
  const {activeToken} = props;
  //CREATE CHIP HISTORY ARRAY STATE
 
  //ADD CHIP ID
  function placeBet(element, betType, numbers, positionID) {
    let betAmount = 0;



    //case props.activeToken.chips IMG then betAmount = chips.value
    // 14/04/23 have to update bet amounts, wont match var names anymore

    let betIndex = activeToken.chips.findIndex((element) => element.image == props.selectedChip);
    if(betIndex < 0) 
      betIndex = 0;
    betAmount = activeToken.chips[betIndex].value;
    console.log(props.selectedChip);
    console.log(betAmount);

    // switch(props.selectedChip) {
    //   case _003ETH:
    //     betAmount = 0.0004;
    //     break;
    //   case _006ETH:
    //     betAmount = 0.0008;
    //     break;
    //   case _015ETH:
    //     betAmount = 0.0016;
    //     break;
    //   case _03ETH:
    //     betAmount = 0.004;
    //     break;
    //   case _06ETH:
    //     betAmount = 0.008;
    //     break;
    // }

    betAmount = getMaxBettable(betType, betAmount, numbers);

    if(betAmount <= 0) {
      alert("Bet not placed, it would exceed table max.");
      return;
    }

    if(updateBetsArray(betType, numbers, betAmount)) {
      

      increaseBet(betAmount);

      //UPDATE CHIP STATE
      let chipStateCopy = {...chipState};
      if(chipStateCopy[positionID]) {
        chipStateCopy[positionID] = formatDecimal(addDecimals(chipStateCopy[positionID], betAmount));
      } else {
        chipStateCopy[positionID] = parseFloat(betAmount);
      }
      setChipState(chipStateCopy);

      let currentBetAmount = betAmount;
      const newChipHistory = [...chipHistory, {positionID: positionID,  betAmount: currentBetAmount}];
      props.setCanUndo(true);
      setChipHistory(newChipHistory);
    } else {
      alert("Bet not placed, it would exceed table max.");
    }
  }

  // track bets to be sent to backend
  function updateBetsArray(betType, numbers, betAmount) {
    let dividedAmount = calcBetAmount(betType, betAmount);

    if(betBelowMaxValues(dividedAmount, numbers)) {
      const betNumbers = [];

      for(let i = 0; i < props.bets.length; i++) {
        betNumbers[i] = props.bets[i];
      }
  
      betNumbers.push([numbers, dividedAmount]);
  
      props.setBets(betNumbers);

      return true;
    } else {
      return false
    }
  }
  
  function getMaxBettable(betType, betAmount, numbers) {
    let dividedAmount = calcBetAmount(betType, betAmount);
    //UPDATE MAX VALUE
    let maxValueToCheck = activeToken.max_bet;
    let maxDividedAmount = 0;
    numbers.forEach((element) => {
      let numberBettable = Math.min(subtractDecimals(maxValueToCheck, maxBets[element]), dividedAmount);
      if(maxDividedAmount == 0) {
        maxDividedAmount = numberBettable;
      }
      maxDividedAmount = Math.min(maxDividedAmount, numberBettable);
    });
    maxDividedAmount = Math.max(0, maxDividedAmount);
    // console.log(maxDividedAmount);
    return formatDecimal(calcChipAmount(betType, maxDividedAmount));
  }

  function betBelowMaxValues(betAmount, numbers) {
    let allowBet = false;
    const maxBetsCopy = [];
    let maxBetsBelowCount = 0;
    let maxValueToCheck = 0;

    for(let i = 0; i < maxBets.length; i++) {
      maxBetsCopy[i] = maxBets[i];
    }

    for(let i = 0; i < numbers.length; i++) {
      //UPDATE MAX VALUE
      maxValueToCheck = activeToken.max_bet;

      if(addDecimals(maxBets[numbers[i]], betAmount) <= maxValueToCheck) {
        maxBetsBelowCount++;
      }
    }

    if(maxBetsBelowCount == numbers.length) {
      allowBet = true;

      for(let i = 0; i < numbers.length; i++) {
        maxBetsCopy[numbers[i]] = addDecimals(maxBets[numbers[i]], betAmount);
        if(maxBetsCopy[numbers[i]] > 0);
        console.log(maxBetsCopy[numbers[i]]);
      }

      setMaxBets(maxBetsCopy);

      const newTableHistory = [...tableHistory, maxBetsCopy];
      setTableHistory(newTableHistory);
    }

    return allowBet;
  }

  function increaseBet(newBet) {
    let betAmount = addDecimals(betTotal, newBet);
    //console.log("bet total after adding " + betAmount)
    updateBetAmount(betAmount);
  }

  function decreaseBet(reduction) {
    let betAmount = subtractDecimals(betTotal, reduction);
    //console.log("bet total after adding " + betAmount)
    updateBetAmount(betAmount);
  }

  function roundNumber(number, decimals) {
    var newnumber = new Number(number+'').toFixed(parseInt(decimals));
    return parseFloat(newnumber); 
  }

  // for the UI
  function updateBetAmount(currentBet) {
    setTotalBet(currentBet);
    props.setBetInFull(currentBet);

    props.setBetText(formatDecimal(currentBet).toString());
  }

  function calcWinnings(betType, betAmount) {
    let potentialWinnings = 0;

    switch(betType) {
      case 'Straight-up':
        potentialWinnings = (betAmount * 35) + betAmount;
        break;
      case 'Split':
        potentialWinnings = (betAmount * 17) + betAmount;
        break;
      case 'Street':
        potentialWinnings = (betAmount * 11) + betAmount;
        break;
      case 'Corner':
        potentialWinnings = (betAmount * 8) + betAmount;
        break;
      case 'Line':
        potentialWinnings = (betAmount * 5) + betAmount;
        break;
      case 'Basket':
        potentialWinnings = (betAmount * 6) + betAmount;
        break;
      case 'Dozen':
        potentialWinnings = (betAmount * 2) + betAmount;
        break;
      case 'Column':
        potentialWinnings = (betAmount * 2) + betAmount;
        break;
      case 'Color':
        potentialWinnings = (betAmount * 1) + betAmount;
        break;
      case 'Even/Odd':
        potentialWinnings = (betAmount * 1) + betAmount;
        break;
      case 'Low/High':
        potentialWinnings = (betAmount * 1) + betAmount;
        break;
    }

    return potentialWinnings;
  }

  function calcChipAmount(betType, betAmount) {
    let _betAmount = 0;

    switch(betType) {
      case 'Straight-up':
        _betAmount = betAmount;
        break;
      case 'Split':
        _betAmount = multiplyDecimalByWhole(betAmount, 2);
        break;
      case 'Street':
        _betAmount = multiplyDecimalByWhole(betAmount, 3);
        break;
      case 'Corner':
        _betAmount = multiplyDecimalByWhole(betAmount, 4);
        break;
      case 'Line':
        _betAmount = multiplyDecimalByWhole(betAmount, 6);
        break;
      case 'Basket':
        _betAmount = multiplyDecimalByWhole(betAmount, 5);
        break;
      case 'Dozen':
        _betAmount = multiplyDecimalByWhole(betAmount, 12);
        break;
      case 'Column':
        _betAmount = multiplyDecimalByWhole(betAmount, 12);
        break;
      case 'Color':
        _betAmount = multiplyDecimalByWhole(betAmount, 18);
        break;
      case 'Even/Odd':
        _betAmount = multiplyDecimalByWhole(betAmount, 18);
        break;
      case 'Low/High':
        _betAmount = multiplyDecimalByWhole(betAmount, 18);
        break;
    }

    return _betAmount;
  }

  function calcBetAmount(betType, betAmount) {
    let _betAmount = 0;

    switch(betType) {
      case 'Straight-up':
        _betAmount = betAmount;
        break;
      case 'Split':
        _betAmount = divideDecimalByWhole(betAmount, 2);
        break;
      case 'Street':
        _betAmount = divideDecimalByWhole(betAmount, 3);
        break;
      case 'Corner':
        _betAmount = divideDecimalByWhole(betAmount, 4);
        break;
      case 'Line':
        _betAmount = divideDecimalByWhole(betAmount, 6);
        break;
      case 'Basket':
        _betAmount = divideDecimalByWhole(betAmount, 5);
        break;
      case 'Dozen':
        _betAmount = divideDecimalByWhole(betAmount, 12);
        break;
      case 'Column':
        _betAmount = divideDecimalByWhole(betAmount, 12);
        break;
      case 'Color':
        _betAmount = divideDecimalByWhole(betAmount, 18);
        break;
      case 'Even/Odd':
        _betAmount = divideDecimalByWhole(betAmount, 18);
        break;
      case 'Low/High':
        _betAmount = divideDecimalByWhole(betAmount, 18);
        break;
    }

    return _betAmount;
  }

  function subtractDecimals(num1, num2) {
    var tempNum1 = num1.toString();
    var tempNum2 = num2.toString();
    var length1 = (tempNum1.split('.')[1] || []).length;
    var length2 = (tempNum2.split('.')[1] || []).length;
    var maxLength = Math.max(length1, length2);
    var factor = Math.pow(10, maxLength);
    return (Math.round(num1 * factor) - Math.round(num2 * factor)) / factor;
  }

  function addDecimals(num1, num2) {
    var tempNum1 = num1.toString();
    var tempNum2 = num2.toString();
    var length1 = (tempNum1.split('.')[1] || []).length;
    var length2 = (tempNum2.split('.')[1] || []).length;
    var maxLength = Math.max(length1, length2);
    var factor = Math.pow(10, maxLength);
    return (Math.round(num1 * factor) + Math.round(num2 * factor)) / factor;
  }

  function multiplyDecimalByWhole(num1, num2) {
    var tempNum1 = num1.toString();
    var length1 = (tempNum1.split('.')[1] || []).length;
    var factor = Math.pow(10, length1);
    return (Math.round(num1 * factor) * num2) / factor;
  }

  function divideDecimalByWhole(num1, num2) {
    if (num2 === 0) {
        return 'Error: Division by zero is not allowed.';
    }
    var tempNum1 = num1.toString();
    var length1 = (tempNum1.split('.')[1] || []).length;
    var factor = Math.pow(10, length1);
    return (Math.round(num1 * factor) / num2) / factor;
  }

  function formatDecimal(number) {
    var tempNum = number.toString();
    var length = Math.min((tempNum.split('.')[1] || []).length, 4);
    var factor = Math.pow(10, length);
    return (Math.round(number * factor)) / factor;
  }

  function undo() {
    if(chipHistory.length > 0) {
      //SUBTRACT CHIP VALUE USING CHIP HISTORY

      const newChipHistory = [...chipHistory];

      let toUndo = newChipHistory.pop();

      let chipStateCopy = {...chipState};
      chipStateCopy[toUndo.positionID] = subtractDecimals(chipStateCopy[toUndo.positionID], toUndo.betAmount);
      setChipState(chipStateCopy);
      setChipHistory(newChipHistory);

      decreaseBet(toUndo.betAmount);

      const history = [];

      for(let i = 0; i < tableHistory.length; i++) {
        history[i] = tableHistory[i];
      }

      if(history.length > 0) {
        history.pop();
        setTableHistory(history);

        if(history.length == 0) {
          setMaxBets(
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0]);
        } else {
          setMaxBets(history[history.length - 1]);
        }
      }
      
      let bets = props.bets;
      bets.pop();
      props.setBets(bets);
      props.setCanUndo(history.length > 0);
    }
  }

  function repeatBet() {
    props.setBets([...previousBets]);
    setMaxBets([...previousMaxBets]);
    setChipState({...previousChips});
    setTotalBet(previousTotalBet);
  }

  function savePreviousBet() {
    setPreviousBets([...(props.bets)])
    setPreviousMaxBets([...maxBets]);
    setPreviousChips({...chipState});
    setPreviousTotalBet(betTotal);
  }

  function clearAll() {
    setChipHistory([]);
    setChipState({});

    setTotalBet(0);
    setTableHistory([]);
    props.setBetText('0');
    props.setBets([]);
    props.setBetInFull();
    setMaxBets(
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0]);
    props.setCanUndo(false);
  }

  function highlight(ids) {
    for(let i = 0; i < ids.length; i++) {
      document.getElementById(ids[i]).className += ' highlight';
    }
  }

  function removeHighlight(ids) {
    for(let i = 0; i < ids.length; i++) {
      document.getElementById(ids[i]).classList.remove('highlight');
    }
  }

  // from parent
  useImperativeHandle(ref, () => ({
    undo() {
      undo();
    },
    clearAll() {
      clearAll();
    },
    repeatBet() {
      repeatBet();
    },
    savePreviousBet() {
      savePreviousBet();
    },
  }))


  return (
    <div className="table-wrapper">
      <div className="table">
        <div className="top-section">
          <div className="zero-split">
            <div className="zero-split-column" id="00" onClick={e => placeBet(e, "Straight-up", [37], "00")} onMouseLeave={() => removeHighlight(["00"])} onMouseEnter={() => highlight(["00"])}>
              <div className="number-text">00</div>
              <Chip chipList={activeToken.chips} amount={chipState["00"] ? chipState["00"] : 0}/>
            </div>
            <div className="zero-split-column" id="0" onClick={e => placeBet(e, "Straight-up", [0], "0")} onMouseLeave={() => removeHighlight(["0"])} onMouseEnter={() => highlight(["0"])}>
              <div className="number-text">0</div>
              <Chip chipList={activeToken.chips} amount={chipState["0"] ? chipState["0"] : 0}/>
            </div>
          </div><div className="numbers">
            <div className="numbers-row numbers-row-top">
              <div className="number number-red-color" id="3">
                <div className="number-text">3</div>
              </div>
              <div className="number number-black-color" id="6">
                <div className="number-text">6</div>
              </div>
              <div className="number number-red-color" id="9">
                <div className="number-text">9</div>
              </div>
              <div className="number number-red-color" id="12">
                <div className="number-text">12</div>
              </div>
              <div className="number number-black-color" id="15">
                <div className="number-text">15</div>
              </div>
              <div className="number number-red-color" id="18">
                <div className="number-text">18</div>
              </div>
              <div className="number number-red-color" id="21">
                <div className="number-text">21</div>
              </div>
              <div className="number number-black-color" id="24">
                <div className="number-text">24</div>
              </div>
              <div className="number number-red-color" id="27">
                <div className="number-text">27</div>
              </div>
              <div className="number number-red-color" id="30">
                <div className="number-text">30</div>
              </div>
              <div className="number number-black-color" id="33">
                <div className="number-text">33</div>
              </div>
              <div className="number number-red-color" id="36">
                <div className="number-text">36</div>
              </div>
            </div><div className="numbers-row">
              <div className="number number-black-color" id="2">
                <div className="number-text">2</div>
              </div>
              <div className="number number-red-color" id="5">
                <div className="number-text">5</div>
              </div>
              <div className="number number-black-color" id="8">
                <div className="number-text">8</div>
              </div>
              <div className="number number-black-color" id="11">
                <div className="number-text">11</div>
              </div>
              <div className="number number-red-color" id="14">
                <div className="number-text">14</div>
              </div>
              <div className="number number-black-color" id="17">
                <div className="number-text">17</div>
              </div>
              <div className="number number-black-color" id="20">
                <div className="number-text">20</div>
              </div>
              <div className="number number-red-color" id="23">
                <div className="number-text">23</div>
              </div>
              <div className="number number-black-color" id="26">
                <div className="number-text">26</div>
              </div>
              <div className="number number-black-color" id="29">
                <div className="number-text">29</div>
              </div>
              <div className="number number-red-color" id="32">
                <div className="number-text">32</div>
              </div>
              <div className="number number-black-color" id="35">
                <div className="number-text">35</div>
              </div>
            </div><div className="numbers-row">
              <div className="number number-red-color" id="1">
                <div className="number-text">1</div>
              </div>
              <div className="number number-black-color" id="4">
                <div className="number-text">4</div>
              </div>
              <div className="number number-red-color" id="7">
                <div className="number-text">7</div>
              </div>
              <div className="number number-black-color" id="10">
                <div className="number-text">10</div>
              </div>
              <div className="number number-black-color" id="13">
                <div className="number-text">13</div>
              </div>
              <div className="number number-red-color" id="16">
                <div className="number-text">16</div>
              </div>
              <div className="number number-red-color" id="19">
                <div className="number-text">19</div>
              </div>
              <div className="number number-black-color" id="22">
                <div className="number-text">22</div>
              </div>
              <div className="number number-red-color" id="25">
                <div className="number-text">25</div>
              </div>
              <div className="number number-black-color" id="28">
                <div className="number-text">28</div>
              </div>
              <div className="number number-black-color" id="31">
                <div className="number-text">31</div>
              </div>
              <div className="number number-red-color" id="34">
                <div className="number-text">34</div>
              </div>
            </div>
          </div><div className="dozen">
            <div className="dozen-column" id="dozen1" onClick={e => placeBet(e, "Dozen", [3,6,9,12,15,18,21,24,27,30,33,36], "dozen1")} onMouseLeave={() => removeHighlight(["dozen1", "3", "6", "9", "12", "15", "18", "21", "24", "27", "30", "33", "36"])} 
            onMouseEnter={() => highlight(["dozen1", "3", "6", "9", "12", "15", "18", "21", "24", "27", "30", "33", "36"])}>
              <div className="dozen-text">2 to 1</div>
              <Chip chipList={activeToken.chips} amount={chipState["dozen1"] ? chipState["dozen1"] : 0}/>
            </div>
            <div className="dozen-column" id="dozen2" onClick={e => placeBet(e, "Dozen", [2,5,8,11,14,17,20,23,26,29,32,35], "dozen2")} onMouseLeave={() => removeHighlight(["dozen2", "2", "5", "8", "11", "14", "17", "20", "23", "26", "29", "32", "35"])} 
            onMouseEnter={() => highlight(["dozen2", "2", "5", "8", "11", "14", "17", "20", "23", "26", "29", "32", "35"])}>
              <div className="dozen-text">2 to 1</div>
              <Chip chipList={activeToken.chips} amount={chipState["dozen2"] ? chipState["dozen2"] : 0}/>
            </div>
            <div className="dozen-column" id="dozen3" onClick={e => placeBet(e, "Dozen", [1,4,7,10,13,16,19,22,25,28,31,34], "dozen3")} onMouseLeave={() => removeHighlight(["dozen3", "1", "4", "7", "10", "13", "16", "19", "22", "25", "28", "31", "34"])} 
            onMouseEnter={() => highlight(["dozen3", "1", "4", "7", "10", "13", "16", "19", "22", "25", "28", "31", "34"])}>
              <div className="dozen-text">2 to 1</div>
              <Chip chipList={activeToken.chips} amount={chipState["dozen3"] ? chipState["dozen3"] : 0}/>
            </div>
          </div>
        </div>
        <div className="mid-section">
          <div className="number-group left" id="numbergroup1" onClick={e => placeBet(e, "Column", [1,2,3,4,5,6,7,8,9,10,11,12], "numbergroup1")} onMouseLeave={() => removeHighlight(["numbergroup1", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"])} 
          onMouseEnter={() => highlight(["numbergroup1", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"])}>
            <div className="number-group-text">1 - 12</div>
            <Chip chipList={activeToken.chips} amount={chipState["numbergroup1"] ? chipState["numbergroup1"] : 0}/>
          </div>
          <div className="number-group" id="numbergroup2" onClick={e => placeBet(e, "Column", [13,14,15,16,17,18,19,20,21,22,23,24], "numbergroup2")} onMouseLeave={() => removeHighlight(["numbergroup2", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"])} 
          onMouseEnter={() => highlight(["numbergroup2", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"])}>
            <div className="number-group-text">13 - 24</div>
            <Chip chipList={activeToken.chips} amount={chipState["numbergroup2"] ? chipState["numbergroup2"] : 0}/>
          </div>
          <div className="number-group right" id="numbergroup3" onClick={e => placeBet(e, "Column", [25,26,27,28,29,30,31,32,33,34,35,36], "numbergroup3")} onMouseLeave={() => removeHighlight(["numbergroup3", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36"])} 
          onMouseEnter={() => highlight(["numbergroup3", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36"])}>
            <div className="number-group-text">25 to 36</div>
            <Chip chipList={activeToken.chips} amount={chipState["numbergroup3"] ? chipState["numbergroup3"] : 0}/>
          </div>
        </div>
        <div className="bottom-section">
          <div className="bottom-group left" id="numbergroup4" onClick={e => placeBet(e, "Low/High", [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18], "numbergroup4")} onMouseLeave={() => removeHighlight(["numbergroup4", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18"])} 
          onMouseEnter={() => highlight(["numbergroup4", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18"])}>
            <div className="bottom-number-group-text">1 to 18</div>
            <Chip chipList={activeToken.chips} amount={chipState["numbergroup4"] ? chipState["numbergroup4"] : 0}/>
          </div>
          <div className="bottom-group" id="even" onClick={e => placeBet(e, "Even/Odd", [2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36], "even")} onMouseLeave={() => removeHighlight(["even", "2", "4", "6", "8", "10", "12", "14", "16", "18", "20", "22", "24", "26", "28", "30", "32", "34", "36"])} 
          onMouseEnter={() => highlight(["even", "2", "4", "6", "8", "10", "12", "14", "16", "18", "20", "22", "24", "26", "28", "30", "32", "34", "36"])}>
            <div className="odd-even-text">EVEN</div>
            <Chip chipList={activeToken.chips} amount={chipState["even"] ? chipState["even"] : 0}/>
          </div>
          <div className="bottom-group" id="red" onClick={e => placeBet(e, "Color", [1,3,5,7,9,12,14,16,18,19,21,23,25,27,30,32,34,36], "red")} onMouseLeave={() => removeHighlight(["red", "1", "3", "5", "7", "9", "12", "14", "16", "18","19", "21", "23", "25", "27", "30", "32", "34", "36"])} 
          onMouseEnter={() => highlight(["red", "1", "3", "5", "7", "9", "12", "14", "16", "18","19", "21", "23", "25", "27", "30", "32", "34", "36"])}>
            <div className="diamond-red"></div>
            <div className="diamond-red-2"></div>
            <Chip chipList={activeToken.chips} amount={chipState["red"] ? chipState["red"] : 0}/>
          </div>
          <div className="bottom-group" id="black" onClick={e => placeBet(e, "Color", [2,4,6,8,10,11,13,15,17,20,22,24,26,28,29,31,33,35], "black")} onMouseLeave={() => removeHighlight(["black", "2", "4", "6", "8", "10", "11", "13", "15", "17", "20", "22", "24", "26", "28", "29", "31", "33", "35"])} 
          onMouseEnter={() => highlight(["black", "2", "4", "6", "8", "10", "11", "13", "15", "17", "20", "22", "24", "26", "28", "29", "31", "33", "35"])}>
            <div className="diamond-black"></div>
            <div className="diamond-black-2"></div>
            <Chip chipList={activeToken.chips} amount={chipState["black"] ? chipState["black"] : 0}/>
          </div>
          <div className="bottom-group" id="odd" onClick={e => placeBet(e, "Even/Odd", [1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31,33,35], "odd")} onMouseLeave={() => removeHighlight(["odd", "1", "3", "5", "7", "9", "11", "13", "15", "17", "19", "21", "23", "25", "27", "29", "31", "33", "35"])} 
          onMouseEnter={() => highlight(["odd", "1", "3", "5", "7", "9", "11", "13", "15", "17", "19", "21", "23", "25", "27", "29", "31", "33", "35"])}>
            <div className="odd-even-text">ODD</div>
            <Chip chipList={activeToken.chips} amount={chipState["odd"] ? chipState["odd"] : 0}/>
          </div>
          <div className="bottom-group right" id="numbergroup5" onClick={e => placeBet(e, "Low/High", [19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36], "numbergroup5")} onMouseLeave={() => removeHighlight(["numbergroup5", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36"])} 
          onMouseEnter={() => highlight(["numbergroup5", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36"])}>
            <div className="bottom-number-group-text">19 to 36</div>
            <Chip chipList={activeToken.chips} amount={chipState["numbergroup5"] ? chipState["numbergroup5"] : 0}/>
          </div>
        </div>
      </div>

      <div className="collisions-wrapper">
        <div className="collisions-top-section">
          <div className="collisions-numbers">
            <div id="special-box-4" onClick={e => placeBet(e, "Split", [2,37], "split200")} onMouseLeave={() => removeHighlight(["2", "00"])} onMouseEnter={() => highlight(["2", "00"])}>
              <Chip chipList={activeToken.chips} amount={chipState["split200"] ? chipState["split200"] : 0}/>
            </div>
            <div id="special-box-5" onClick={e => placeBet(e, "Split", [2,0], "split20")} onMouseLeave={() => removeHighlight(["2", "0"])} onMouseEnter={() => highlight(["2", "0"])}>
              <Chip chipList={activeToken.chips} amount={chipState["split20"] ? chipState["split20"] : 0}/>  
            </div>
            <div id="special-box-6" onClick={e => placeBet(e, "Split", [0,37], "split000")} onMouseLeave={() => removeHighlight(["0", "00"])} onMouseEnter={() => highlight(["0", "00"])}>
              <Chip chipList={activeToken.chips} amount={chipState["split000"] ? chipState["split000"] : 0}/>
            </div>
            <div className="collision-box-row">
              <div className="collision-box" onClick={e => placeBet(e, "Basket", [3,2,1,0,37], "basket321000")} onMouseLeave={() => removeHighlight(["3", "2", "1", "0", "00"])} onMouseEnter={() => highlight(["3", "2", "1", "0", "00"])}>
                <Chip chipList={activeToken.chips} amount={chipState["basket321000"] ? chipState["basket321000"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [3,2,1], "street321")} onMouseLeave={() => removeHighlight(["3", "2", "1"])} onMouseEnter={() => highlight(["3", "2", "1"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street321"] ? chipState["street321"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [1,2,3,4,5,6], "line123456")} onMouseLeave={() => removeHighlight(["3", "2", "1", "4", "5", "6"])} onMouseEnter={() => highlight(["3", "2", "1", "4", "5", "6"])}>  
                <Chip chipList={activeToken.chips} amount={chipState["line123456"] ? chipState["line123456"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [4,5,6], "street456")} onMouseLeave={() => removeHighlight(["4", "5", "6"])} onMouseEnter={() => highlight(["4", "5", "6"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street456"] ? chipState["street456"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [4,5,6,7,8,9], "line456789")} onMouseLeave={() => removeHighlight(["7", "8", "9", "4", "5", "6"])} onMouseEnter={() => highlight(["7", "8", "9", "4", "5", "6"])}>
                <Chip chipList={activeToken.chips} amount={chipState["line456789"] ? chipState["line456789"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [7,8,9], "street789")} onMouseLeave={() => removeHighlight(["7", "8", "9"])} onMouseEnter={() => highlight(["7", "8", "9"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street789"] ? chipState["street789"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [7,8,9,10,11,12], "line789101112")} onMouseLeave={() => removeHighlight(["7", "8", "9", "10", "11", "12"])} onMouseEnter={() => highlight(["7", "8", "9", "10", "11", "12"])}>
                <Chip chipList={activeToken.chips} amount={chipState["line789101112"] ? chipState["line789101112"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [10,11,12], "street101112")} onMouseLeave={() => removeHighlight(["10", "11", "12"])} onMouseEnter={() => highlight(["10", "11", "12"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street101112"] ? chipState["street101112"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [10,11,12,13,14,15], "line101112131415")} onMouseLeave={() => removeHighlight(["10", "11", "12", "13", "14", "15"])} onMouseEnter={() => highlight(["10", "11", "12", "13", "14", "15"])}>
                <Chip chipList={activeToken.chips} amount={chipState["line101112131415"] ? chipState["line101112131415"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [13,14,15], "street131415")} onMouseLeave={() => removeHighlight(["13", "14", "15"])} onMouseEnter={() => highlight(["13", "14", "15"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street131415"] ? chipState["street131415"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [13,14,15,16,17,18], "line131415161718")} onMouseLeave={() => removeHighlight(["13", "14", "15", "16", "17", "18"])} onMouseEnter={() => highlight(["13", "14", "15", "16", "17", "18"])}>
                <Chip chipList={activeToken.chips} amount={chipState["line131415161718"] ? chipState["line131415161718"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [16,17,18], "street161718")} onMouseLeave={() => removeHighlight(["16", "17", "18"])} onMouseEnter={() => highlight(["16", "17", "18"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street161718"] ? chipState["street161718"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [16,17,18,19,20,21], "line161718192021")} onMouseLeave={() => removeHighlight(["16", "17", "18", "19", "20", "21"])} onMouseEnter={() => highlight(["16", "17", "18", "19", "20", "21"])}>
                <Chip chipList={activeToken.chips} amount={chipState["line161718192021"] ? chipState["line161718192021"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [19,20,21], "street192021")} onMouseLeave={() => removeHighlight(["19", "20", "21"])} onMouseEnter={() => highlight(["19", "20", "21"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street192021"] ? chipState["street192021"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [19,20,21,22,23,24], "line192021222324")} onMouseLeave={() => removeHighlight(["19", "20", "21", "22", "23", "24"])} onMouseEnter={() => highlight(["19", "20", "21", "22", "23", "24"])}>
                <Chip chipList={activeToken.chips} amount={chipState["line192021222324"] ? chipState["line192021222324"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [22,23,24], "street222324")} onMouseLeave={() => removeHighlight(["22", "23", "24"])} onMouseEnter={() => highlight(["22", "23", "24"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street222324"] ? chipState["street222324"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [22,23,24,25,26,27], "line222324252627")} onMouseLeave={() => removeHighlight(["22", "23", "24", "25", "26", "27"])} onMouseEnter={() => highlight(["22", "23", "24", "25", "26", "27"])}>
                <Chip chipList={activeToken.chips} amount={chipState["line222324252627"] ? chipState["line222324252627"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [25,26,27], "street252627")} onMouseLeave={() => removeHighlight(["25", "26", "27"])} onMouseEnter={() => highlight(["25", "26", "27"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street252627"] ? chipState["street252627"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [25,26,27,28,29,30], "line252627282930")} onMouseLeave={() => removeHighlight(["25", "26", "27", "28", "29", "30"])} onMouseEnter={() => highlight(["25", "26", "27", "28", "29", "30"])}>
                <Chip chipList={activeToken.chips} amount={chipState["line252627282930"] ? chipState["line252627282930"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [28,29,30], "street282930")} onMouseLeave={() => removeHighlight(["28", "29", "30"])} onMouseEnter={() => highlight(["28", "29", "30"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street282930"] ? chipState["street282930"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [28,29,30,31,32,33], "line282930313233")} onMouseLeave={() => removeHighlight(["28", "29", "30", "31", "32", "33"])} onMouseEnter={() => highlight(["28", "29", "30", "31", "32", "33"])}>
                <Chip chipList={activeToken.chips} amount={chipState["line282930313233"] ? chipState["line282930313233"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [31,32,33], "street313233")} onMouseLeave={() => removeHighlight(["31", "32", "33"])} onMouseEnter={() => highlight(["31", "32", "33"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street313233"] ? chipState["street313233"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [31,32,33,34,35,36], "line313233343536")} onMouseLeave={() => removeHighlight(["31", "32", "33", "34", "35", "36"])} onMouseEnter={() => highlight(["31", "32", "33", "34", "35", "36"])}>
                <Chip chipList={activeToken.chips} amount={chipState["line313233343536"] ? chipState["line313233343536"] : 0}/>
              </div>
              <div className="collision-box end-box" onClick={e => placeBet(e, "Street", [34,35,36], "street343536")} onMouseLeave={() => removeHighlight(["34", "35", "36"])} onMouseEnter={() => highlight(["34", "35", "36"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street343536"] ? chipState["street343536"] : 0}/>
              </div>
            </div>
            <div className="collision-box-row">
              <div className="collision-box" onClick={e => placeBet(e, "Split", [3,37], "split300")} onMouseLeave={() => removeHighlight(["3", "00"])} onMouseEnter={() => highlight(["3", "00"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split300"] ? chipState["split300"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [3], "3")} onMouseLeave={() => removeHighlight(["3"])} onMouseEnter={() => highlight(["3"])}>
                <Chip chipList={activeToken.chips} amount={chipState["3"] ? chipState["3"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [3,6], "split36")} onMouseLeave={() => removeHighlight(["3", "6"])} onMouseEnter={() => highlight(["3", "6"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split36"] ? chipState["split36"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [6], "6")} onMouseLeave={() => removeHighlight(["6"])} onMouseEnter={() => highlight(["6"])}>
                <Chip chipList={activeToken.chips} amount={chipState["6"] ? chipState["6"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [6,9], "split69")} onMouseLeave={() => removeHighlight(["6", "9"])} onMouseEnter={() => highlight(["6", "9"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split69"] ? chipState["split69"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [9], "9")} onMouseLeave={() => removeHighlight(["9"])} onMouseEnter={() => highlight(["9"])}>
                <Chip chipList={activeToken.chips} amount={chipState["9"] ? chipState["9"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [9,12], "split912")} onMouseLeave={() => removeHighlight(["9", "12"])} onMouseEnter={() => highlight(["9", "12"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split912"] ? chipState["split912"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [12], "12")} onMouseLeave={() => removeHighlight(["12"])} onMouseEnter={() => highlight(["12"])}>
                <Chip chipList={activeToken.chips} amount={chipState["12"] ? chipState["12"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [12,15], "split1215")} onMouseLeave={() => removeHighlight(["12", "15"])} onMouseEnter={() => highlight(["12", "15"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1215"] ? chipState["split1215"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [15], "15")} onMouseLeave={() => removeHighlight(["15"])} onMouseEnter={() => highlight(["15"])}>
                <Chip chipList={activeToken.chips} amount={chipState["15"] ? chipState["15"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [15,18], "split1518")} onMouseLeave={() => removeHighlight(["15", "18"])} onMouseEnter={() => highlight(["15", "18"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1518"] ? chipState["split1518"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [18], "18")} onMouseLeave={() => removeHighlight(["18"])} onMouseEnter={() => highlight(["18"])}>
                <Chip chipList={activeToken.chips} amount={chipState["18"] ? chipState["18"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [18,21], "split1821")} onMouseLeave={() => removeHighlight(["18", "21"])} onMouseEnter={() => highlight(["18", "21"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1821"] ? chipState["split1821"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [21], "21")} onMouseLeave={() => removeHighlight(["21"])} onMouseEnter={() => highlight(["21"])}>
                <Chip chipList={activeToken.chips} amount={chipState["21"] ? chipState["21"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [21,24], "split2124")} onMouseLeave={() => removeHighlight(["21", "24"])} onMouseEnter={() => highlight(["21", "24"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2124"] ? chipState["split2124"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [24], "24")} onMouseLeave={() => removeHighlight(["24"])} onMouseEnter={() => highlight(["24"])}>
                <Chip chipList={activeToken.chips} amount={chipState["24"] ? chipState["24"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [24,27], "split2427")} onMouseLeave={() => removeHighlight(["24", "27"])} onMouseEnter={() => highlight(["24", "27"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2427"] ? chipState["split2427"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [27], "27")} onMouseLeave={() => removeHighlight(["27"])} onMouseEnter={() => highlight(["27"])}>
                <Chip chipList={activeToken.chips} amount={chipState["27"] ? chipState["27"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [27,30], "split2730")} onMouseLeave={() => removeHighlight(["27", "30"])} onMouseEnter={() => highlight(["27", "30"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2730"] ? chipState["split2730"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [30], "30")} onMouseLeave={() => removeHighlight(["30"])} onMouseEnter={() => highlight(["30"])}>
                <Chip chipList={activeToken.chips} amount={chipState["30"] ? chipState["30"] : 0}/>
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [30,33], "split3033")} onMouseLeave={() => removeHighlight(["30", "33"])} onMouseEnter={() => highlight(["30", "33"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split3033"] ? chipState["split3033"] : 0}/>
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [33], "33")} onMouseLeave={() => removeHighlight(["33"])} onMouseEnter={() => highlight(["33"])}>
                <Chip chipList={activeToken.chips} amount={chipState["33"] ? chipState["33"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [33,36], "split3336")} onMouseLeave={() => removeHighlight(["33", "36"])} onMouseEnter={() => highlight(["33", "36"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split3336"] ? chipState["split3336"] : 0}/> 
              </div>
              <div className="collision-box end-box" onClick={e => placeBet(e, "Straight-up", [36], "36")} onMouseLeave={() => removeHighlight(["36"])} onMouseEnter={() => highlight(["36"])}>
                <Chip chipList={activeToken.chips} amount={chipState["36"] ? chipState["36"] : 0}/> 
              </div>
            </div>
            <div className="collision-box-row">
              <div className="collision-box" id="special-box-1" onClick={e => placeBet(e, "Street", [3,2,37], "street3200")} onMouseLeave={() => removeHighlight(["3", "2", "00"])} onMouseEnter={() => highlight(["3", "2", "00"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street3200"] ? chipState["street3200"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [3,2], "split32")} onMouseLeave={() => removeHighlight(["3", "2"])} onMouseEnter={() => highlight(["3", "2"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split32"] ? chipState["split32"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [3,2,5,6], "corner3256")} onMouseLeave={() => removeHighlight(["3", "2", "5", "6"])} onMouseEnter={() => highlight(["3", "2", "5", "6"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner3256"] ? chipState["corner3256"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [5,6], "split56")} onMouseLeave={() => removeHighlight(["5", "6"])} onMouseEnter={() => highlight(["5", "6"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split56"] ? chipState["split56"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [5,6,8,9], "corner5689")} onMouseLeave={() => removeHighlight(["5", "6", "8", "9"])} onMouseEnter={() => highlight(["8", "9", "5", "6"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner5689"] ? chipState["corner5689"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [8,9], "split89")} onMouseLeave={() => removeHighlight(["8", "9"])} onMouseEnter={() => highlight(["8", "9"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split89"] ? chipState["split89"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [8,9,11,12], "corner891112")} onMouseLeave={() => removeHighlight(["8", "9", "11", "12"])} onMouseEnter={() => highlight(["8", "9", "11", "12"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner891112"] ? chipState["corner891112"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [11,12], "split1112")} onMouseLeave={() => removeHighlight(["11", "12"])} onMouseEnter={() => highlight(["11", "12"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1112"] ? chipState["split1112"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [11,12,14,15], "corner11121415")} onMouseLeave={() => removeHighlight(["11", "12", "14", "15"])} onMouseEnter={() => highlight(["11", "12", "14", "15"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner11121415"] ? chipState["corner11121415"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [14,15], "split1415")} onMouseLeave={() => removeHighlight(["14", "15"])} onMouseEnter={() => highlight(["14", "15"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1415"] ? chipState["split1415"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [14,15,17,18], "corner14151718")} onMouseLeave={() => removeHighlight(["14", "15", "17", "18"])} onMouseEnter={() => highlight(["14", "15", "17", "18"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner14151718"] ? chipState["corner14151718"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [17,18], "split1718")} onMouseLeave={() => removeHighlight(["17", "18"])} onMouseEnter={() => highlight(["17", "18"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1718"] ? chipState["split1718"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [17,18,20,21], "corner17182021")} onMouseLeave={() => removeHighlight(["17", "18", "20", "21"])} onMouseEnter={() => highlight(["17", "18", "20", "21"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner17182021"] ? chipState["corner17182021"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [20, 21], "split2021")} onMouseLeave={() => removeHighlight(["20", "21"])} onMouseEnter={() => highlight(["20", "21"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2021"] ? chipState["split2021"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [20,21,23,24], "corner20212324")} onMouseLeave={() => removeHighlight(["20", "21", "23", "24"])} onMouseEnter={() => highlight(["20", "21", "23", "24"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner20212324"] ? chipState["corner20212324"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [23,24], "split2324")} onMouseLeave={() => removeHighlight(["23", "24"])} onMouseEnter={() => highlight(["23", "24"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2324"] ? chipState["split2324"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [23,24,26,27], "corner23242627")} onMouseLeave={() => removeHighlight(["23", "24", "26", "27"])} onMouseEnter={() => highlight(["23", "24", "26", "27"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner23242627"] ? chipState["corner23242627"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [26,27], "split2627")} onMouseLeave={() => removeHighlight(["26", "27"])} onMouseEnter={() => highlight(["26", "27"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2627"] ? chipState["split2627"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [26,27,29,30], "corner26272930")} onMouseLeave={() => removeHighlight(["26", "27", "29", "30"])} onMouseEnter={() => highlight(["26", "27", "29", "30"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner26272930"] ? chipState["corner26272930"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [29,30], "split2930")} onMouseLeave={() => removeHighlight(["29", "30"])} onMouseEnter={() => highlight(["29", "30"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2930"] ? chipState["split2930"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [29,30,32,33], "corner29303233")} onMouseLeave={() => removeHighlight(["29", "30", "32", "33"])} onMouseEnter={() => highlight(["29", "30", "32", "33"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner29303233"] ? chipState["corner29303233"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [32,33], "split3233")} onMouseLeave={() => removeHighlight(["32", "33"])} onMouseEnter={() => highlight(["32", "33"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split3233"] ? chipState["split3233"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [32,33,35,36], "corner32333536")} onMouseLeave={() => removeHighlight(["32", "33", "35", "36"])} onMouseEnter={() => highlight(["32", "33", "35", "36"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner32333536"] ? chipState["corner32333536"] : 0}/> 
              </div>
              <div className="collision-box end-box" onClick={e => placeBet(e, "Split", [35,36], "split3536")} onMouseLeave={() => removeHighlight(["35", "36"])} onMouseEnter={() => highlight(["35", "36"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split3536"] ? chipState["split3536"] : 0}/> 
              </div>
            </div>
            <div className="collision-box-row">
              <div className="collision-box" id="special-box-3" onClick={e => placeBet(e, "Street", [2,0,37], "street2000")} onMouseLeave={() => removeHighlight(["2", "00", "0"])} onMouseEnter={() => highlight(["2", "00", "0"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street2000"] ? chipState["street2000"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [2], "2")} onMouseLeave={() => removeHighlight(["2"])} onMouseEnter={() => highlight(["2"])}>
                <Chip chipList={activeToken.chips} amount={chipState["2"] ? chipState["2"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [2,5], "split25")} onMouseLeave={() => removeHighlight(["2", "5"])} onMouseEnter={() => highlight(["2", "5"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split25"] ? chipState["split25"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [5], "5")} onMouseLeave={() => removeHighlight(["5"])} onMouseEnter={() => highlight(["5"])}>
                <Chip chipList={activeToken.chips} amount={chipState["5"] ? chipState["5"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [5,8], "split58")} onMouseLeave={() => removeHighlight(["5", "8"])} onMouseEnter={() => highlight(["5", "8"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split58"] ? chipState["split58"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [8], "8")} onMouseLeave={() => removeHighlight(["8"])} onMouseEnter={() => highlight(["8"])}>
                <Chip chipList={activeToken.chips} amount={chipState["8"] ? chipState["8"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [8,11], "split811")} onMouseLeave={() => removeHighlight(["8", "11"])} onMouseEnter={() => highlight(["8", "11"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split811"] ? chipState["split811"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [11], "11")} onMouseLeave={() => removeHighlight(["11"])} onMouseEnter={() => highlight(["11"])}>
                <Chip chipList={activeToken.chips} amount={chipState["11"] ? chipState["11"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [11,14], "split1114")} onMouseLeave={() => removeHighlight(["11", "14"])} onMouseEnter={() => highlight(["11", "14"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1114"] ? chipState["split1114"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [14], "14")} onMouseLeave={() => removeHighlight(["14"])} onMouseEnter={() => highlight(["14"])}>
                <Chip chipList={activeToken.chips} amount={chipState["14"] ? chipState["14"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [14,17], "split1417")} onMouseLeave={() => removeHighlight(["14", "17"])} onMouseEnter={() => highlight(["14", "17"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1417"] ? chipState["split1417"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [17], "17")} onMouseLeave={() => removeHighlight(["17"])} onMouseEnter={() => highlight(["17"])}>
                <Chip chipList={activeToken.chips} amount={chipState["17"] ? chipState["17"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [17,20], "split1720")} onMouseLeave={() => removeHighlight(["17", "20"])} onMouseEnter={() => highlight(["17", "20"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1720"] ? chipState["split1720"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [20], "20")} onMouseLeave={() => removeHighlight(["20"])} onMouseEnter={() => highlight(["20"])}>
                <Chip chipList={activeToken.chips} amount={chipState["20"] ? chipState["20"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [20, 23], "split2023")} onMouseLeave={() => removeHighlight(["20", "23"])} onMouseEnter={() => highlight(["20", "23"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2023"] ? chipState["split2023"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [23], "23")} onMouseLeave={() => removeHighlight(["23"])} onMouseEnter={() => highlight(["23"])}>
                <Chip chipList={activeToken.chips} amount={chipState["23"] ? chipState["23"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [23, 26], "split2326")} onMouseLeave={() => removeHighlight(["23", "26"])} onMouseEnter={() => highlight(["23", "26"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2326"] ? chipState["split2326"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [26], "26")} onMouseLeave={() => removeHighlight(["26"])} onMouseEnter={() => highlight(["26"])}>
                <Chip chipList={activeToken.chips} amount={chipState["26"] ? chipState["26"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [26, 29], "split2629")} onMouseLeave={() => removeHighlight(["26", "29"])} onMouseEnter={() => highlight(["26", "29"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2629"] ? chipState["split2629"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [29], "29")} onMouseLeave={() => removeHighlight(["29"])} onMouseEnter={() => highlight(["29"])}>
                <Chip chipList={activeToken.chips} amount={chipState["29"] ? chipState["29"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [29,32], "split2932")} onMouseLeave={() => removeHighlight(["29", "32"])} onMouseEnter={() => highlight(["29", "32"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2932"] ? chipState["split2932"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [32], "32")} onMouseLeave={() => removeHighlight(["32"])} onMouseEnter={() => highlight(["32"])}>
                <Chip chipList={activeToken.chips} amount={chipState["32"] ? chipState["32"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [32,35], "split3235")} onMouseLeave={() => removeHighlight(["32", "35"])} onMouseEnter={() => highlight(["32", "35"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split3235"] ? chipState["split3235"] : 0}/> 
              </div>
              <div className="collision-box end-box" onClick={e => placeBet(e, "Straight-up", [35], "35")} onMouseLeave={() => removeHighlight(["35"])} onMouseEnter={() => highlight(["35"])}>
                <Chip chipList={activeToken.chips} amount={chipState["35"] ? chipState["35"] : 0}/> 
              </div>
            </div>
            <div className="collision-box-row">
              <div className="collision-box" id="special-box-2" onClick={e => placeBet(e, "Street", [1,2,0], "street120")} onMouseLeave={() => removeHighlight(["1", "2", "0"])} onMouseEnter={() => highlight(["1", "2", "0"])}>
                <Chip chipList={activeToken.chips} amount={chipState["street120"] ? chipState["street120"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [1,2], "split12")} onMouseLeave={() => removeHighlight(["1", "2"])} onMouseEnter={() => highlight(["1", "2"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split12"] ? chipState["split12"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [1,2,5,4], "corner1254")} onMouseLeave={() => removeHighlight(["1", "2", "4", "5"])} onMouseEnter={() => highlight(["1", "2", "5", "4"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner1254"] ? chipState["corner1254"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [4,5], "split45")} onMouseLeave={() => removeHighlight(["4", "5"])} onMouseEnter={() => highlight(["5", "4"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split45"] ? chipState["split45"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [7,8,5,4], "corner7854")} onMouseLeave={() => removeHighlight(["4", "5", "7", "8"])} onMouseEnter={() => highlight(["4", "5", "7", "8"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner7854"] ? chipState["corner7854"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [7,8], "split78")} onMouseLeave={() => removeHighlight(["7", "8"])} onMouseEnter={() => highlight(["7", "8"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split78"] ? chipState["split78"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [7,8,10,11], "corner781011")} onMouseLeave={() => removeHighlight(["7", "8", "10", "11"])} onMouseEnter={() => highlight(["7", "8", "10", "11"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner781011"] ? chipState["corner781011"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [10,11], "split1011")} onMouseLeave={() => removeHighlight(["10", "11"])} onMouseEnter={() => highlight(["10", "11"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1011"] ? chipState["split1011"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [10,11,13,14], "corner10111314")} onMouseLeave={() => removeHighlight(["10", "11", "13", "14"])} onMouseEnter={() => highlight(["10", "11", "13", "14"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner10111314"] ? chipState["corner10111314"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [13,14], "split1314")} onMouseLeave={() => removeHighlight(["13", "14"])} onMouseEnter={() => highlight(["13", "14"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1314"] ? chipState["split1314"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [13,14,16,17], "corner13141617")} onMouseLeave={() => removeHighlight(["13", "14", "16", "17"])} onMouseEnter={() => highlight(["14", "13", "17", "16"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner13141617"] ? chipState["corner13141617"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [16,17], "split1617")} onMouseLeave={() => removeHighlight(["16", "17"])} onMouseEnter={() => highlight(["17", "16"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1617"] ? chipState["split1617"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [16,17,19,20], "corner16171920")} onMouseLeave={() => removeHighlight(["16", "17", "19", "20"])} onMouseEnter={() => highlight(["17", "16", "19", "20"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner16171920"] ? chipState["corner16171920"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [19,20], "split1920")} onMouseLeave={() => removeHighlight(["19", "20"])} onMouseEnter={() => highlight(["20", "19"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1920"] ? chipState["split1920"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [19,20,22,23], "corner19202223")} onMouseLeave={() => removeHighlight(["19", "20", "22", "23"])} onMouseEnter={() => highlight(["20", "19", "22", "23"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner19202223"] ? chipState["corner19202223"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [23, 22], "split2322")} onMouseLeave={() => removeHighlight(["23", "22"])} onMouseEnter={() => highlight(["23", "22"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2322"] ? chipState["split2322"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [22,23,25,26], "corner22232526")} onMouseLeave={() => removeHighlight(["22", "23", "25", "26"])} onMouseEnter={() => highlight(["22", "23", "25", "26"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner22232526"] ? chipState["corner22232526"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [26,25], "split2625")} onMouseLeave={() => removeHighlight(["26", "25"])} onMouseEnter={() => highlight(["26", "25"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2625"] ? chipState["split2625"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [25,26,28,29], "corner25262829")} onMouseLeave={() => removeHighlight(["26", "25", "28", "29"])} onMouseEnter={() => highlight(["26", "25", "28", "29"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner25262829"] ? chipState["corner25262829"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [29,28], "split2928")} onMouseLeave={() => removeHighlight(["29", "28"])} onMouseEnter={() => highlight(["29", "28"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2928"] ? chipState["split2928"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [28,29,31,32], "corner28293132")} onMouseLeave={() => removeHighlight(["29", "28", "31", "32"])} onMouseEnter={() => highlight(["28", "29", "31", "32"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner28293132"] ? chipState["corner28293132"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Split", [32,31], "split3231")} onMouseLeave={() => removeHighlight(["32", "31"])} onMouseEnter={() => highlight(["31", "32"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split3231"] ? chipState["split3231"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Corner", [31,32,34,35], "corner31323435")} onMouseLeave={() => removeHighlight(["32", "31", "34", "35"])} onMouseEnter={() => highlight(["31", "32", "34", "35"])}>
                <Chip chipList={activeToken.chips} amount={chipState["corner31323435"] ? chipState["corner31323435"] : 0}/> 
              </div>
              <div className="collision-box end-box" onClick={e => placeBet(e, "Split", [35,34], "split3534")} onMouseLeave={() => removeHighlight(["35", "34"])} onMouseEnter={() => highlight(["34", "35"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split3534"] ? chipState["split3534"] : 0}/> 
              </div>
            </div>
            <div className="collision-box-row">
              <div className="collision-box" onClick={e => placeBet(e, "Split", [1,0], "split10")} onMouseLeave={() => removeHighlight(["1", "0"])} onMouseEnter={() => highlight(["1", "0"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split10"] ? chipState["split10"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [1], "1")} onMouseLeave={() => removeHighlight(["1"])} onMouseEnter={() => highlight(["1"])}>
                <Chip chipList={activeToken.chips} amount={chipState["1"] ? chipState["1"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [1,4], "split14")} onMouseLeave={() => removeHighlight(["1", "4"])} onMouseEnter={() => highlight(["1", "4"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split14"] ? chipState["split14"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [4], "4")} onMouseLeave={() => removeHighlight(["4"])} onMouseEnter={() => highlight(["4"])}>
                <Chip chipList={activeToken.chips} amount={chipState["4"] ? chipState["4"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [4,7], "split47")} onMouseLeave={() => removeHighlight(["4", "7"])} onMouseEnter={() => highlight(["4", "7"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split47"] ? chipState["split47"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [7], "7")} onMouseLeave={() => removeHighlight(["7"])} onMouseEnter={() => highlight(["7"])}>
                <Chip chipList={activeToken.chips} amount={chipState["7"] ? chipState["7"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [7,10], "split710")} onMouseLeave={() => removeHighlight(["7", "10"])} onMouseEnter={() => highlight(["7", "10"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split710"] ? chipState["split710"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [10], "10")} onMouseLeave={() => removeHighlight(["10"])} onMouseEnter={() => highlight(["10"])}>
                <Chip chipList={activeToken.chips} amount={chipState["10"] ? chipState["10"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [10,13], "split1013")} onMouseLeave={() => removeHighlight(["10", "13"])} onMouseEnter={() => highlight(["10", "13"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1013"] ? chipState["split1013"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [13], "13")} onMouseLeave={() => removeHighlight(["13"])} onMouseEnter={() => highlight(["13"])}>
                <Chip chipList={activeToken.chips} amount={chipState["13"] ? chipState["13"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [13,16], "split1316")} onMouseLeave={() => removeHighlight(["13", "16"])} onMouseEnter={() => highlight(["13", "16"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1316"] ? chipState["split1316"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [16], "16")} onMouseLeave={() => removeHighlight(["16"])} onMouseEnter={() => highlight(["16"])}>
               <Chip chipList={activeToken.chips} amount={chipState["16"] ? chipState["16"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [16,19], "split1619")} onMouseLeave={() => removeHighlight(["16", "19"])} onMouseEnter={() => highlight(["16", "19"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1619"] ? chipState["split1619"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [19], "19")} onMouseLeave={() => removeHighlight(["19"])} onMouseEnter={() => highlight(["19"])}>
               <Chip chipList={activeToken.chips} amount={chipState["19"] ? chipState["19"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [19,22], "split1922")} onMouseLeave={() => removeHighlight(["19", "22"])} onMouseEnter={() => highlight(["19", "22"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split1922"] ? chipState["split1922"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [22], "22")} onMouseLeave={() => removeHighlight(["22"])} onMouseEnter={() => highlight(["22"])}>
                <Chip chipList={activeToken.chips} amount={chipState["22"] ? chipState["22"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [22,25], "split2225")} onMouseLeave={() => removeHighlight(["22", "25"])} onMouseEnter={() => highlight(["22", "25"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2225"] ? chipState["split2225"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [25], "25")} onMouseLeave={() => removeHighlight(["25"])} onMouseEnter={() => highlight(["25"])}>
                <Chip chipList={activeToken.chips} amount={chipState["25"] ? chipState["25"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [25,28], "split2528")} onMouseLeave={() => removeHighlight(["25", "28"])} onMouseEnter={() => highlight(["25", "28"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2528"] ? chipState["split2528"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [28], "28")} onMouseLeave={() => removeHighlight(["28"])} onMouseEnter={() => highlight(["28"])}>
                <Chip chipList={activeToken.chips} amount={chipState["28"] ? chipState["28"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [28,31], "split2831")} onMouseLeave={() => removeHighlight(["28", "31"])} onMouseEnter={() => highlight(["28", "31"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split2831"] ? chipState["split2831"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Straight-up", [31], "31")} onMouseLeave={() => removeHighlight(["31"])} onMouseEnter={() => highlight(["31"])}>
                <Chip chipList={activeToken.chips} amount={chipState["31"] ? chipState["31"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Split", [31,34], "split3134")} onMouseLeave={() => removeHighlight(["31", "34"])} onMouseEnter={() => highlight(["31", "34"])}>
                <Chip chipList={activeToken.chips} amount={chipState["split3134"] ? chipState["split3134"] : 0}/> 
              </div>
              <div className="collision-box end-box" onClick={e => placeBet(e, "Straight-up", [34], "34")} onMouseLeave={() => removeHighlight(["34"])} onMouseEnter={() => highlight(["34"])}>
                <Chip chipList={activeToken.chips} amount={chipState["34"] ? chipState["34"] : 0}/> 
              </div>
            </div>
            <div className="collision-box-row">
            <div className="collision-box" onClick={e => placeBet(e, "Basket", [1,2,3,0,37], "basket123000b")} onMouseLeave={() => removeHighlight(["3", "2", "1", "0", "00"])} onMouseEnter={() => highlight(["3", "2", "1", "0", "00"])}>
                <Chip chipList={activeToken.chips} amount={chipState["basket123000b"] ? chipState["basket123000b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [3,2,1], "street321b")} onMouseLeave={() => removeHighlight(["3", "2", "1"])} onMouseEnter={() => highlight(["3", "2", "1"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street321b"] ? chipState["street321b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [1,2,3,4,5,6], "line123456b")} onMouseLeave={() => removeHighlight(["3", "2", "1", "4", "5", "6"])} onMouseEnter={() => highlight(["3", "2", "1", "4", "5", "6"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line123456b"] ? chipState["line123456b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [4,5,6], "street456b")} onMouseLeave={() => removeHighlight(["4", "5", "6"])} onMouseEnter={() => highlight(["4", "5", "6"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street456b"] ? chipState["street456b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [7,8,9,4,5,6], "line789456b")} onMouseLeave={() => removeHighlight(["7", "8", "9", "4", "5", "6"])} onMouseEnter={() => highlight(["7", "8", "9", "4", "5", "6"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line789456b"] ? chipState["line789456b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [7,8,9], "street789b")} onMouseLeave={() => removeHighlight(["7", "8", "9"])} onMouseEnter={() => highlight(["7", "8", "9"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street789b"] ? chipState["street789b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [7,8,9,10,11,12], "line789101112b")} onMouseLeave={() => removeHighlight(["7", "8", "9", "10", "11", "12"])} onMouseEnter={() => highlight(["7", "8", "9", "10", "11", "12"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line789101112b"] ? chipState["line789101112b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [10,11,12], "street101112b")} onMouseLeave={() => removeHighlight(["10", "11", "12"])} onMouseEnter={() => highlight(["10", "11", "12"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street101112b"] ? chipState["street101112b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [10,11,12,13,14,15], "line101112131415b")} onMouseLeave={() => removeHighlight(["10", "11", "12", "13", "14", "15"])} onMouseEnter={() => highlight(["10", "11", "12", "13", "14", "15"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line101112131415b"] ? chipState["line101112131415b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [13,14,15], "street131415b")} onMouseLeave={() => removeHighlight(["13", "14", "15"])} onMouseEnter={() => highlight(["13", "14", "15"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street131415b"] ? chipState["street131415b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [13,14,15,16,17,18], "line131415161718b")} onMouseLeave={() => removeHighlight(["13", "14", "15", "16", "17", "18"])} onMouseEnter={() => highlight(["13", "14", "15", "16", "17", "18"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line131415161718b"] ? chipState["line131415161718b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [16,17,18], "street161718b")} onMouseLeave={() => removeHighlight(["16", "17", "18"])} onMouseEnter={() => highlight(["16", "17", "18"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street161718b"] ? chipState["street161718b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [16,17,18,19,20,21], "line161718192021b")} onMouseLeave={() => removeHighlight(["16", "17", "18", "19", "20", "21"])} onMouseEnter={() => highlight(["16", "17", "18", "19", "20", "21"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line161718192021b"] ? chipState["line161718192021b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [19,20,21], "street192021b")} onMouseLeave={() => removeHighlight(["19", "20", "21"])} onMouseEnter={() => highlight(["19", "20", "21"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street192021b"] ? chipState["street192021b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [19,20,21,22,23,24], "line192021222324b")} onMouseLeave={() => removeHighlight(["19", "20", "21", "22", "23", "24"])} onMouseEnter={() => highlight(["19", "20", "21", "22", "23", "24"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line192021222324b"] ? chipState["line192021222324b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [22,23,24], "street222324b")} onMouseLeave={() => removeHighlight(["22", "23", "24"])} onMouseEnter={() => highlight(["22", "23", "24"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street222324b"] ? chipState["street222324b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [22,23,24,25,26,27], "line222324252627b")} onMouseLeave={() => removeHighlight(["22", "23", "24", "25", "26", "27"])} onMouseEnter={() => highlight(["22", "23", "24", "25", "26", "27"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line222324252627b"] ? chipState["line222324252627b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [25,26,27], "street252627b")} onMouseLeave={() => removeHighlight(["25", "26", "27"])} onMouseEnter={() => highlight(["25", "26", "27"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street252627b"] ? chipState["street252627b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [25,26,27,28,29,30], "line252627282930b")} onMouseLeave={() => removeHighlight(["25", "26", "27", "28", "29", "30"])} onMouseEnter={() => highlight(["25", "26", "27", "28", "29", "30"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line252627282930b"] ? chipState["line252627282930b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [28,29,30], "street282930b")} onMouseLeave={() => removeHighlight(["28", "29", "30"])} onMouseEnter={() => highlight(["28", "29", "30"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street282930b"] ? chipState["street282930b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [28,29,30,31,32,33], "line282930313233b")} onMouseLeave={() => removeHighlight(["28", "29", "30", "31", "32", "33"])} onMouseEnter={() => highlight(["28", "29", "30", "31", "32", "33"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line282930313233b"] ? chipState["line282930313233b"] : 0}/> 
              </div>
              <div className="collision-box centre-box" onClick={e => placeBet(e, "Street", [31,32,33], "street313233b")} onMouseLeave={() => removeHighlight(["31", "32", "33"])} onMouseEnter={() => highlight(["31", "32", "33"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street313233b"] ? chipState["street313233b"] : 0}/> 
              </div>
              <div className="collision-box" onClick={e => placeBet(e, "Line", [31,32,33,34,35,36], "line313233343536b")} onMouseLeave={() => removeHighlight(["31", "32", "33", "34", "35", "36"])} onMouseEnter={() => highlight(["31", "32", "33", "34", "35", "36"])}>
              <Chip chipList={activeToken.chips} amount={chipState["line313233343536b"] ? chipState["line313233343536b"] : 0}/> 
              </div>
              <div className="collision-box end-box" onClick={e => placeBet(e, "Street", [34,35,36], "street343536b")} onMouseLeave={() => removeHighlight(["34", "35", "36"])} onMouseEnter={() => highlight(["34", "35", "36"])}>
              <Chip chipList={activeToken.chips} amount={chipState["street343536b"] ? chipState["street343536b"] : 0}/> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Table)
