import './App.css';
import './animate.css';
import background from './background.png';
import logo from './logo.png';
import wheel from './wheel.png';
import _003ETH from './01BNB.png';
import _006ETH from './02BNB.png';
import _015ETH from './03BNB.png';
import _03ETH from './05BNB.png';
import _06ETH from './1BNB.png';
import ETHICON from './eth.png';
import PEWICON from './pew.png';
import spin from './spin.png';
import Table from './Table';
import DROP from './drop.mp3';
import ROLL from './roll.mp3';
import SLOWDOWN from './slowdown.mp3';
import React, { useEffect, useState, useRef } from 'react';
import {useWeb3Modal} from '@web3modal/wagmi/react';
import {useAccount, useBalance} from 'wagmi';
import { writeContract, watchContractEvent, waitForTransactionReceipt, readContract} from '@wagmi/core'
import { parseEther, formatEther } from 'viem';
import RouletteContract from './contracts/Roulette.json';
import PEWABI from './contracts/PEWABI.json';
import { config } from './web3modal.js';
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import axios from "axios";
import useSound from "use-sound";

const ETH_TOKEN = {
  mainnet_contract: "0xf3D77E4233bf7c5984A411b19131621cA63e2c53",
  testnet_contract: "0x6F8D59C326bDDcBcA4CcbE2dB61D724c187E0Bec",
  chips: [
    {
      image: _003ETH,
      value: 0.0004
    },
    {
      image: _006ETH,
      value: 0.0008
    },
    {
      image: _015ETH,
      value: 0.0016
    },
    {
      image: _03ETH,
      value: 0.004
    },
    {
      image: _06ETH,
      value: 0.008
    }
  ],
  max_bet: 0.0016,
  token_name: "$ETH",
  token_image: ETHICON,
  value_color: "#FFFFFF"
};

const PEW_TOKEN = {
  mainnet_contract: "0xD4A9da04372E33fbdB127270D7c3B79e04a68630",
  testnet_contract: "0x09A256fb3cE4DeB94a658Cb6bAC536b8D04932e5",
  chips: [
    {
      image: _003ETH,
      value: 180
    },
    {
      image: _006ETH,
      value: 360
    },
    {
      image: _015ETH,
      value: 720
    },
    {
      image: _03ETH,
      value: 1440
    },
    {
      image: _06ETH,
      value: 2880
    }
  ],
  max_bet: 720,
  token_name: "$PEW",
  token_image: PEWICON,
  token_mainnet: "0x0EE485dfD0f4ef8C4faBC1Ab93dbcC03FCC05c57",
  token_testnet: "0x5Cb18Bd1DB84049b47b822859833Ff1C390B43A4"
}

const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

const competitionEnd = new Date("2024-06-24T21:00:00.00Z").getTime();

function App() {
  const [betText, setBetText] = useState('0');
  const [previousTotalBet, setPreviousTotalBet] = useState(0);
  const [selectedChip, setChip] = useState();
  const [spinResult, setSpinResult] = useState();
  const [bets, setBets] = useState([]);
  const [betInFull, setBetInFull] = useState();
  const [chips, setChips] = useState([_003ETH, _006ETH, _015ETH, _03ETH, _06ETH]) 
  const table = useRef();
  const { address, chainId, status, connector, isDisconnected } = useAccount()
  const { open } = useWeb3Modal()
  const [isClosableOnClick, setClosableOnClick] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const {data: balance, refetch, error} = useBalance({address: address});
  const [winningNumbers, setWinningNumbers] = useState([]);
  const [activeToken, setActiveToken] = useState(ETH_TOKEN);
  const [customTokenBalance, setCustomTokenBalance] = useState(0);
  const [customTokenAllowance, setCustomTokenAllowance] = useState(0);
  const [isDaily, setIsDaily] = useState(true);
  const isClosableOnClickRef = useRef(isClosableOnClick);
  const [timeLeft, setTimeLeft] = useState("00:00:00:00");
  const [rollSFX, {stop: stopRoll}] = useSound(ROLL, {loop: true});
  const [dropSFX] = useSound(DROP);
  const [slowSFX] = useSound(SLOWDOWN);



  const x = setInterval(function() {

    // Get today's date and time
    var now = new Date().getTime();
      
    // Find the distance between now and the count down date
    var distance = competitionEnd - now;
      
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      
    // Output the result in an element with id="demo"
    setTimeLeft(`ends in ${days.toString().padStart(2, 0)}:${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`)
      
    // If the count down is over, write some text 
    if (distance < 0) {
      clearInterval(x);
      setTimeLeft('ENDED')
    }
  }, 1000);
  const _setClosableOnClick = (value) => {
    isClosableOnClickRef.current = value;
    setClosableOnClick(value);
  }

  const wheelRef = useRef();
  const ballRef = useRef();
  const timelineRef = useRef();

  const [leaderboard, setLeaderboard] = useState({"eth" : [], "pew" : {"alltime": [], "daily": []}});

  const truncateAddy = (addy) => {
    const match = addy.match(truncateRegex);
    if (!match) return addy;
    return `${match[1]}…${match[2]}`;
  };


  //HOUSE 0x713a8114a4129c990F71516633476648dC6C4987

  // const ROULETTE_ADDRESS = "0x6F8D59C326bDDcBcA4CcbE2dB61D724c187E0Bec"; // testnet
  //const HOUSE_ADDRESS = "0x9F5fbB42Bb655A3d118F85A6Db22ddD12FA07D3d";

  // const ROULETTE_ADDRESS = "0xf3D77E4233bf7c5984A411b19131621cA63e2c53"; // live
  //const HOUSE_ADDRESS = "0xE30d2846514A667ADd9c19cfE604De008e0abBA6";

  // const requiredChainID = 81457; // blast mainnet
  // const requiredChainID = 168587773;

  useGSAP(
    () => {
        // ✅ safe, created during execution, selector text scoped
        gsap.to(wheelRef.current, { rotation: 360, duration: 10, repeat: -1, ease: "none"});
    },
    { scope: wheelRef }
  );

  useGSAP(
    () => {
      timelineRef.current = gsap.timeline({paused: true});
    },
    {scope: ballRef}
  );

  useEffect(() => {
    var dropdownOpen = false;
    var hamburger = document.getElementById("open-mobile-menu");
    tokenPopup();

    // axios.get("https://rouletteonblast.com:4433/leaderboard").then((result) => {
    //   if(result.data) {
    //     setLeaderboard(result.data);
    //   }
    // })

    axios.get("https://rouletteonblast.com:4433/leaderboards").then((result) => {
      console.log(result);
      if(result.data) {
        setLeaderboard(result.data);
      }
    })
  
    if (hamburger.addEventListener) {
      hamburger.addEventListener("click", function() {
        dropdownOpen = true;
  
        document.getElementById('dropdown').style.width = "100%";

        //document.getElementById('dropdown-close').className += 'animated fadeIn';
        document.getElementById('dropdown-close').style.display = 'block';
  
        document.getElementById('mobile-navLinks').className += 'animated fadeIn';
        document.getElementById('mobile-navLinks').style.display = 'flex';
      }, false);
    }

    var el = document.getElementById("dropdown-close");
  
    if (el.addEventListener) {
      el.addEventListener("click", function() {
        document.getElementById('dropdown-close').style.display = 'none';
        document.getElementById('mobile-navLinks').style.display = 'none';
        dropdownOpen = false;
        document.getElementById('dropdown').style.width = "0px";
      }, false);
    }

    document.getElementById("popup-wrapper").addEventListener("click", ( e ) => {
      e = window.event || e; 
      // console.log(isClosableOnClick);
      let wrapper = document.getElementById('popup-wrapper');
      if(wrapper == e.target && isClosableOnClickRef.current == true) {
        document.getElementById('popup-wrapper').style.display = 'none';
        document.getElementById('popup-info').style.visibility = 'hidden';
        document.getElementById('popup-howto').style.visibility = 'hidden';
        document.getElementById('popup-leaderboard').style.visibility = 'hidden';
      }
    });

    if(window.ethereum) {
      init();
      
      window.ethereum.on('accountsChanged', accounts => {
        if (accounts.length) {
          console.log("switched account in metamask");
          init();
        } else {
          console.log("logged out of metamask");
          // setAccounts([]);
        }
      });
  
      window.ethereum.on('chainChanged', function(networkId) {
        init();
      });
    }
  }, []);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  async function init() {
    console.log(chainId);

    // let network = await web3.eth.net.getId();

    // const accounts = await web3.eth.getAccounts();
    // setAccounts(accounts);
    // setAccountString(accounts[0].toString().substring(0, 8) + "...");

    // let contract = new web3.eth.Contract(RouletteContract.abi, ROULETTE_ADDRESS);
    // setContract(contract);

    // let balance = 0;

    setChips([_003ETH, _006ETH, _015ETH, _03ETH, _06ETH]);
    // console.log(balance);
    // console.log(status);

    // balance = await web3.eth.getBalance(accounts[0]);
    // balance = web3.utils.fromWei(balance.toString(), 'ether');

    // setBalance(balance);

    // reset all the app and UI elements
    setBets([]);
    setBetText('0');
    clearAll();

    setChip(_003ETH);
  }

  var selectToken = (token) => () => {
    setActiveToken(token);
    if(status == 'connected' && token == PEW_TOKEN) {
      let abi = PEWABI.abi;
      let token = PEW_TOKEN.token_testnet;
      if(chainId == 81457) {
        token = PEW_TOKEN.token_mainnet;
      }
      readContract(config, {
        abi,
        address: token,
        functionName: 'balanceOf',
        args: [address.toString()],
      }).then((result) => {
        setCustomTokenBalance(formatEther(result));
        if(formatEther(result) > 0) {
          readContract(config, {
            abi,
            address: token,
            functionName: 'allowance',
            args: [address.toString(), chainId == 81457 ? PEW_TOKEN.mainnet_contract : PEW_TOKEN.testnet_contract],
          }).then((allowance) => {
              setCustomTokenAllowance(formatEther(allowance));
          });
        }
      })
    }
    closePopup();
    popup(`Please note that participation in on-chain roulette is at your own risk.\n
You should only engage in this activity if it is legal in your jurisdiction.\n 
We are not responsible for any legal consequences that may arise from your participation.\n 
By playing, you acknowledge and accept all associated risks, including the potential loss of funds.\n 
Our on-chain roulette operates with full transparency and fairness, leveraging blockchain technology to ensure that all outcomes are fair.\n 
Always gamble responsibly.`)
  }


  var dropdownToken = (event) => {
    if(event.target.value === "$ETH")
      setActiveToken(ETH_TOKEN);
    else  {
      setActiveToken(PEW_TOKEN);
      console.log(chainId);
      let token = PEW_TOKEN.token_testnet;
      if(chainId == 81457) {
        token = PEW_TOKEN.token_mainnet;
      }
      if(status == 'connected') {
        let abi = PEWABI.abi;
        readContract(config, {
          abi,
          address: token,
          functionName: 'balanceOf',
          args: [address.toString()],
        }).then((result) => {
          setCustomTokenBalance(formatEther(result));
          if(result > 0) {
            readContract(config, {
              abi,
              address: token,
              functionName: 'allowance',
              args: [address.toString(), chainId == 81457 ? PEW_TOKEN.mainnet_contract : PEW_TOKEN.testnet_contract],
            }).then((allowance) => {
                setCustomTokenAllowance(formatEther(allowance))
            });
          }
        })
      }
    }
    clearAll();
  }

  async function undo() {
    table.current.undo();
  }

  function clearAll() {
    table.current.clearAll();
  }

  function repeatBet() {
    setBetInFull(previousTotalBet);
    setBetText(previousTotalBet.toString());
    table.current.repeatBet();
  }

  function savePreviousBet() {
    table.current.savePreviousBet();
  }

  function addWinningNumber(number) {
    let newwinningNumbers = [...winningNumbers];
    newwinningNumbers.push(number);
    setWinningNumbers(newwinningNumbers.slice(-3));
  }

  function selectChip(chip, element) {
    setChip(chip);
  }

  async function handleRefresh () {
    try {
      if(activeToken == ETH_TOKEN)
        await refetch();
      else {
        let abi = PEWABI.abi;
        let token = activeToken.token_testnet;
        let allowedTokens = 0;
        if(chainId == 81457) {
          token = activeToken.token_mainnet;
        }
        let newBalance = await readContract(config, {
          abi,
          address: token,
          functionName: 'balanceOf',
          args: [address.toString()],
        });
        setCustomTokenBalance(formatEther(newBalance));
        if(formatEther(newBalance) > 0) {
          allowedTokens = await readContract(config, {
            abi,
            address: token,
            functionName: 'allowance',
            args: [address.toString(), chainId == 81457 ? PEW_TOKEN.mainnet_contract : PEW_TOKEN.testnet_contract],
          });
          setCustomTokenAllowance(formatEther(allowedTokens));
          }
        }
    } catch (error) {
      console.error("Error refreshing balance:", error.toString().split('\n')[0]);
    }
  }

  // function resetChips(name) {
  //   let elementsRemove = document.getElementsByClassName("activeChip");

  //   elementsRemove[2].classList.remove('activeChip');
  //   elementsRemove[1].classList.remove('activeChip');
  //   elementsRemove[0].classList.remove('activeChip');

  //   let elementsAdd = document.getElementsByClassName(name);

  //   elementsAdd[2].classList.add('activeChip');
  //   elementsAdd[1].classList.add('activeChip');
  //   elementsAdd[0].classList.add('activeChip');
  // }

  function countDecimals(decimal) {
    var num = parseFloat(decimal); // First convert to number to check if whole

    if(Number.isInteger(num) === true) {
      return 0;
    }

    var text = num.toString(); // Convert back to string and check for "1e-8" numbers
    
    if(text.indexOf('e-') > -1) {
      var [base, trail] = text.split('e-');
      var deg = parseInt(trail, 10);
      return deg;
    } else {
      var index = text.indexOf(".");
      return text.length - index - 1; // Otherwise use simple string function to count
    }
  }

  function toFixed(num, fixed) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  async function spinWheel() {
    document.getElementById('ball-pivot').style.visibility = `hidden`;


    let betObjs = [];

    // how much eth is on each number?
    let finalWagers = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, // 0 - 9
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, // 10 - 19
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, // 20 - 29
      0, 0, 0, 0, 0, 0, 0, 0];

    setSpinResult();

    // 'bets' contains a list of numbers that the bet affects, and how much is being put on them
    // 'final wagers' (generated from above list) is a simplified list of how much (in eth) is on each number
    // 'betObjs' is above list, in object form, each object is the number and how much is on it (in wei), (is sent to contract)

    // break bets down into the 37 numbers
    for(let i = 0; i < bets.length; i++) {
      for(let j = 0; j < bets[i][0].length; j++) {
        finalWagers[bets[i][0][j]] = addDecimals(finalWagers[bets[i][0][j]], bets[i][1])
      }
    }

    // console.log(finalWagers);

    for(let i = 0; i < finalWagers.length; i++) {
      if(finalWagers[i] > 0) { // only include numbers that have bets on them
        if(countDecimals(finalWagers[i]) >= 12) {
          finalWagers[i] = toFixed(finalWagers[i], 18);
        }

        betObjs.push(
          {
            number: i,
            wager: parseEther((finalWagers[i].toString()))
          }
        );
      }
    }

    console.log(betObjs);

    if(betObjs.length > 0) {
      let allowedTokens = customTokenAllowance;
      if(activeToken != ETH_TOKEN && customTokenAllowance <= betInFull && customTokenBalance > 0) {
        try {
        loadingPopup("Please allow contract to handle your $PEW tokens for you by approving the transaction.");
        let token = activeToken.token_testnet;
        if(chainId == 81457) {
          token = activeToken.token_mainnet;
        }
        let abi = PEWABI.abi;

        let approveHash = await writeContract(config, {
          abi,
          address: token ,
          functionName: "approve",
          args: [
            chainId == 81457 ? PEW_TOKEN.mainnet_contract : PEW_TOKEN.testnet_contract,
            parseEther(customTokenBalance)
          ],
          account: address,
          chainId: chainId,
          connector,
        });
        const allowanceReceipt = await waitForTransactionReceipt(config, {
          hash: approveHash,
          confirmations: 5
        })
        allowedTokens = formatEther(await readContract(config, {
          abi,
          address: token,
          functionName: 'allowance',
          args: [address.toString(), chainId == 81457 ? PEW_TOKEN.mainnet_contract : PEW_TOKEN.testnet_contract],
        }));
        setCustomTokenAllowance(formatEther(allowedTokens));
        closePopup();
        } catch (error) {
          closePopup();
          popup(error.toString().split('\n')[0]);
        }}

      if(status == 'connected' && (activeToken == ETH_TOKEN && betInFull <= balance.formatted) || (activeToken != ETH_TOKEN && betInFull <= allowedTokens)) { // can you afford to spin for this bet?


        
        // document.getElementsByClassName("wheel-image")[0].className += ' rotate';

        let abi = RouletteContract.abi;

        try {
          loadingPopup("Please finalize bet by approving transaction.");
          // console.log(activeToken.testnet_contract);
          let commitHash;
          if(activeToken == ETH_TOKEN) {
            commitHash = await writeContract(config, {
              abi,
              address: chainId == 81457 ? activeToken.mainnet_contract : activeToken.testnet_contract ,
              functionName: "commitToBet",
              args: [
                betObjs,
                parseEther(betInFull.toString())
              ],
              account: address,
              chainId: chainId,
              connector,
              value: parseEther(betInFull.toString()),
              
            });
          } else {
            // console.log(betObjs);
            // console.log(betInFull.toString());
            commitHash = await writeContract(config, {
              abi,
              address: chainId == 81457 ? activeToken.mainnet_contract : activeToken.testnet_contract ,
              functionName: "commitToBet",
              args: [
                betObjs,
                parseEther(betInFull.toString())
              ],
              account: address,
              chainId: chainId,
              connector,
            });
          }


          await handleRefresh();

          
          loadingPopup("Processing bet...");


          const transactionReceipt = await waitForTransactionReceipt(config, {
            hash: commitHash,
            confirmations: 5
          })

          while(transactionReceipt.status != 'success') {
            console.log("waiting for transaction");
          }

          loadingPopup("Reveal winning number by approving transaction. Funds won will be automatically sent to your wallet.");

          const spinHash = await writeContract(config, {
            abi,
            address: chainId == 81457 ? activeToken.mainnet_contract : activeToken.testnet_contract ,
            functionName: "revealBet",
            account: address,
            chainId: chainId,
            args: [
              betObjs,
            ],
            connector
          });

          loadingPopup("Processing results...");

          //RELEASE BALL AND ANIMATE
          document.getElementById('ball-pivot').style.visibility = `visible`;
          timelineRef.current.clear();
          timelineRef.current.to(ballRef.current, {rotation: "+=360", duration: 0.75, repeat: -1, ease: "none"});
          dropSFX();
          setTimeout(() => {
            rollSFX();
          }, 389)
          timelineRef.current.play();


          let results = {};

          const unwatch = watchContractEvent(config, {
            abi,
            address: chainId == 81457 ? activeToken.mainnet_contract : activeToken.testnet_contract ,
            eventName: 'PlayerBet',
            onLogs(logs) {
              logs.forEach(function(log) {
                // console.log(log);
                if(log.transactionHash == spinHash) {
                  closePopup();
                  results = log.args;
                  let winnings = formatEther(results.winnings.toString());
                  //SET ANIMATION ANGLE
                  //ROTATE TO WINNING NUMBER;
                  timelineRef.current.pause();
                  let rotationToInitial = 360 - (parseFloat(gsap.getProperty(ballRef.current, 'rotation')) % 360);
                  // console.log(rotationToInitial);
                  let numberAngle = rotationToInitial + 720 + determineAngle(parseInt(results.randomNumber));
                  let duration = numberAngle / 360;
                  timelineRef.current.clear();
                  timelineRef.current.to(ballRef.current, {rotation: "+=" + numberAngle, ease: "power1.out", duration: duration, onComplete: () => {
                    setTimeout(() => {
                      document.getElementById('ball-pivot').style.transform += `translate3d(0px, 0px, 0px) `;
                    }, 25);
                  }});
                  timelineRef.current.play();

                  // const root = document.querySelector(':root');
                  // root.style.setProperty("--end-rotation", `${(360 + determineAngle(parseInt(results.randomNumber)))}deg`);
                  // document.getElementById('ball-pivot').style.animation = `ballRotation 1s ease-out forwards`;

                  setTimeout(() => {
                    stopRoll();
                    slowSFX();
                  }, (duration - 1.983) * 1000);

                  //DELAY 
                  setTimeout(async () => {
                    await handleRefresh();
                    if(winnings > 0) {
                      winnings = toFixed(parseFloat(winnings), 6) + ' ' + activeToken.token_name; 
                      popup("Winnner! Winner!<br></br>" + winnings);
                    }

            
                    if(parseInt(results.randomNumber) == 37) {
                      setSpinResult('00');
                      addWinningNumber('00');
                    } else {
                      setSpinResult(parseInt(results.randomNumber));
                      addWinningNumber(parseInt(results.randomNumber).toString());
                    }

                  }, duration * 1000);
                  
          
                  // document.getElementsByClassName("wheel-image")[0].classList.remove('rotate');
                  unwatch();
                }
              })
              
            }
          })

       
      } catch (error) {
        console.log(error.toString());
        // document.getElementsByClassName("wheel-image")[0].classList.remove('rotate');
        closePopup();
        popup(error.toString().split('\n')[0]);
      }
        setPreviousTotalBet(betInFull);
        savePreviousBet();
        init();
      }
    }
  }

  function connectWallet() {
    if(status == 'connected') {
      popup("Wallet Already Connected.")
    } else {
      init();
    }
  }

  function popup(popupText) {
    _setClosableOnClick(true);
    document.getElementById('popup-text_info').innerHTML = popupText;
    document.getElementById('popup-wrapper').style.display = 'block';
    document.getElementById('popup-info').style.visibility = 'visible';
    document.getElementById('popup-info').className += ' animated fadeIn';
  }

  function loadingPopup(loadingText) {
    _setClosableOnClick(false);
    document.getElementById('popup-loading_text').innerHTML = loadingText;
    document.getElementById('popup-wrapper').style.display = 'block';
    document.getElementById('popup-loading').style.visibility = 'visible';
    document.getElementById('popup-loading').className += ' animated fadeIn';
  }

  var leaderboardPopup = () => {
    _setClosableOnClick(true);
    document.getElementById('popup-wrapper').style.display = 'block';
    document.getElementById('popup-leaderboard').style.visibility = 'visible';
    document.getElementById('popup-leaderboard').className += ' animated fadeIn';
  }

  function tokenPopup() {
    _setClosableOnClick(false);
    // console.log(isClosableOnClick);
    document.getElementById('popup-wrapper').style.display = 'block';
    document.getElementById('popup-token').style.visibility = 'visible';
    document.getElementById('popup-token').className += ' animated fadeIn';
  }


  function howTo() {
    _setClosableOnClick(true);
    document.getElementById('popup-wrapper').style.display = 'block';
    document.getElementById('popup-howto').style.visibility = 'visible';
    document.getElementById('popup-howto').className += ' animated fadeIn';
  }

  function closePopup() {
    document.getElementById('popup-wrapper').style.display = 'none';
    document.getElementById('popup-howto').style.visibility = 'hidden';
    document.getElementById('popup-info').style.visibility = 'hidden';
    document.getElementById('popup-loading').style.visibility = 'hidden';
    document.getElementById('popup-leaderboard').style.visibility = 'hidden';
    document.getElementById('popup-token').style.visibility = 'hidden';
  }

  function addDecimals(num1, num2) {
    var tempNum1 = num1.toString();
    var tempNum2 = num2.toString();
    var length1 = (tempNum1.split('.')[1] || []).length;
    var length2 = (tempNum2.split('.')[1] || []).length;
    var maxLength = Math.max(length1, length2);
    var factor = Math.pow(10, maxLength);
    return (Math.round(num1 * factor) + Math.round(num2 * factor)) / factor;
  }

  function determineAngle(winningNumber) {
    const numberSequence  = [28, 9, 26, 30, 11, 7, 20, 32, 17, 5, 22, 34, 15, 3, 24, 36, 13, 1, 37, 27, 10, 25, 29, 12, 8, 19, 31, 18, 6, 21, 33, 16, 4, 23, 35, 14, 2, 0];
    const anglePerSlot = 360 / numberSequence.length;
    return anglePerSlot * numberSequence.indexOf(winningNumber);
  }

  function determineBackgroundColor(winningNumber) {
    if(winningNumber == null) {
      return "";
    }
    const numberSequence  = [28, 9, 26, 30, 11, 7, 20, 32, 17, 5, 22, 34, 15, 3, 24, 36, 13, 1, 37, 27, 10, 25, 29, 12, 8, 19, 31, 18, 6, 21, 33, 16, 4, 23, 35, 14, 2, 0];
    if(winningNumber == 0 || winningNumber == 37) {
      return "green-result";
    } else if(numberSequence.indexOf(winningNumber) % 2 == 1) {
      return "red-result";
    } else {
      return "black-result";
    }
  }

  function determineColor(winningNumber) {
    if(winningNumber == null) {
      return "";
    }
    const numberSequence  = [28, 9, 26, 30, 11, 7, 20, 32, 17, 5, 22, 34, 15, 3, 24, 36, 13, 1, 37, 27, 10, 25, 29, 12, 8, 19, 31, 18, 6, 21, 33, 16, 4, 23, 35, 14, 2, 0];
    if(winningNumber == "0" || winningNumber == "00") {
      return "green";
    } else if(numberSequence.indexOf(parseInt(winningNumber)) % 2 == 1) {
      return "red";
    } else {
      return "black";
    }
  }

  

  return (
    <div className="App">
      <div className="main-section">
        <img className="section-background" src={background}></img>
        <div id="dropdown">
          <div id="dropdown-content">
            <div id="mobile-navLinks">
              <div className="exit-section">
                <i id="dropdown-close" className="far fa-times-circle"></i>
              </div>
              <a><img className="mobile-nav-logo" src={logo}></img></a>
              <select value={activeToken.token_name} name="token" id="tokens" onChange={dropdownToken}>
                <option value="$ETH">{ETH_TOKEN.token_name}</option>
                <option value="$PEW">{PEW_TOKEN.token_name}</option>
              </select>
              <div className="leaderboard" onClick={leaderboardPopup}>LEADERBOARDS</div>
              {status == 'connected' ? (
                <div className="mobile-wallet-connected-wrapper">
                  <i className="fa mobile-fa-wallet fa-wallet"></i>
                  <div className="mobile-account">{truncateAddy(address)}</div>
                </div>
              ):
                <div className="mobile-wallet" onClick={open}>CONNECT WALLET</div>
              }
            </div>
          </div>
        </div>
        <div className="nav-section">
          <div className="navbar">
            <a><img className="nav-logo" src={logo}></img></a>
            <div className="nav-links-section">
              <select value={activeToken.token_name} name="token" id="tokens" onChange={dropdownToken}>
                <option value="$ETH">{ETH_TOKEN.token_name}</option>
                <option value="$PEW">{PEW_TOKEN.token_name}</option>
              </select>
              <div className="leaderboard" onClick={leaderboardPopup}>LEADERBOARDS</div>
              {status == 'connected' ? (
                <div className="wallet-connected-wrapper">
                  <i className="fas fa-wallet"></i>
                  <div className="account">{truncateAddy(address)}</div>
                </div>
              ):
                <div className="wallet" onClick={open}>CONNECT WALLET</div>
              }
            </div>
            <div id="open-mobile-menu" className="hamburger">
              <span id="top"></span>
              <span id="middle"></span>
              <span id="bottom"></span>
            </div>
          </div>
        </div>


        <div id="popup-wrapper">
          <div className="popup" id="popup-info">
            <div id="popup-text_info"></div>
            <div className="popup-button" onClick={closePopup}>OK</div>
          </div>

          <div className="popup" id="popup-loading">
            <div id="popup-loading_text" className="loading-text"></div>
          </div>

          <div className="popup" id="popup-howto">
            <div className="popup-text">You can bet up to 0.0016ETH or 720 $pew per number. 
			If your number wins, you'll get paid directly to your wallet.</div>
            <div className="popup-header">REWARDS</div>
            <ul className="popup-list">
              <li><b>Competition period:</b> 2 weeks ({timeLeft})</li>
              <li><b>Rewards:</b> 1000 Gold</li>
              <li>Based on Leaderboard ranking</li>
              <ul className="popup-list">
                <li>Top 1: 20%</li>
                <li>Top 2-5: each 6.9%</li>
                <li>Top 6-10: each 4.2%</li>
                <li>Top 11-20: each 0.314%</li>
              </ul>
            </ul>
            <div className="popup-button" onClick={closePopup}>OK</div>
          </div>

          <div className="popup" id="popup-leaderboard">
            <div className="leaderboard-header">LEADERBOARD</div>
            <div>
              {
                //ACTIVE TOKEN IS PEW ADD BUTTONS
                activeToken == PEW_TOKEN ? <div>
                  <div className={!isDaily ? "leaderboard-button" : "disabled-leaderboard-button"} onClick={() => setIsDaily(true)} disabled={isDaily}>Daily</div>
                  <div className={isDaily ? "leaderboard-button" : "disabled-leaderboard-button"} onClick={() => setIsDaily(false)} disabled={!isDaily}>All-time</div>
                </div> : <div/>
              }
              <table cellSpacing="0" cellPadding="0" className="leaderboard-table">
                
                <tr className="leaderboard-row">
                  <th className="leaderboard-th">Rank</th>
                  <th className="leaderboard-th">Address</th>
                  <th className="leaderboard-th">Total Bet</th>
                </tr>
                {
                  //CHANGE BETWEEN PEW (DAILY, ALL TIME) AND ETH 
                  activeToken == ETH_TOKEN ? leaderboard.eth.map((element, index) => <tr key={`${element.name}${index}row`} className="leaderboard-row">
                    <td className="leaderboard-rank leaderboard-data">{index + 1}</td>
                    <td className="leaderboard-data">{element.address}</td>
                    <td className="leaderboard-bet leaderboard-data">{element.totalBet}</td>
                  </tr>): isDaily ? leaderboard.pew.daily.map((element, index) => <tr key={`${element.name}${index}row`} className="leaderboard-row">
                    <td className="leaderboard-rank leaderboard-data">{index + 1}</td>
                    <td className="leaderboard-data">{element.address}</td>
                    <td className="leaderboard-bet leaderboard-data">{element.totalBet}</td>
                  </tr>) : leaderboard.pew.alltime.map((element, index) => <tr key={`${element.name}${index}row`} className="leaderboard-row">
                    <td className="leaderboard-rank leaderboard-data">{index + 1}</td>
                    <td className="leaderboard-data">{element.address}</td>
                    <td className="leaderboard-bet leaderboard-data">{element.totalBet}</td>
                  </tr>)
                }
              </table>
            </div>
            <div className="popup-text"></div>
            <div className="popup-button" onClick={closePopup}>OK</div>
          </div>
          <div className="popup" id="popup-token">
            <div className="popup-header">SELECT TOKEN</div>
            <div className="popup-flex">
              <div className="popup-text token-selection" onClick={selectToken(ETH_TOKEN)}>
                {ETH_TOKEN.token_name}
                <img src={ETHICON} className="token-img"/>
              </div>
              <div className="popup-text token-selection" onClick={selectToken(PEW_TOKEN)}>
                {PEW_TOKEN.token_name}
                <img src={PEWICON} className="token-img"/>
              </div>
            </div>
          </div>
        </div>


        <div className="section-wrapper">
          <div className="header-section">
            <div className="howto-button" onClick={howTo}>
              <div className="howto-button-text">INFO AND REWARDS</div>
            </div>
            <div className="bet-wrapper">
              <div className="bet-info">
              <div className="winning-section bet-left">
                  <div className="winning-header">PREVIOUS:</div>
                  <div className="winning-numbers">{winningNumbers.map((element) => <div className={`winning-number ${determineColor(element)}`}>{element}</div>)}</div>
                  <div className="bet-text"></div>
                </div>
                <div className="bet bet-left">
                  <div className="bet-text">BALANCE</div>
                  <div className="bet-result">{activeToken == ETH_TOKEN ? balance ? toFixed(parseFloat(balance.formatted), 5) : 0 : customTokenBalance ? parseFloat(customTokenBalance) : 0}</div>
                  <div className="bet-text">{activeToken.token_name}</div>
                </div>
                <div className="bet">
                  <div className="bet-text">BET</div>
                  <div className="bet-result">{betText}</div>
                  <div className="bet-text">{activeToken.token_name}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="wheel-wrapper">
            <div ref={wheelRef} className="wheel-pivot">

              <div ref={ballRef} id="ball-pivot">
                <div id="ball"></div>
              </div>
              <img className="wheel-image" src={wheel}></img>
            </div>
            {spinResult != null ? (
              <div className={`result ${determineBackgroundColor(spinResult)}`}>
                <div className="result-text">{spinResult}</div>
              </div>
            ):
              null
            }
          </div>
          <div className="medium-chips">
            <div className="sm-flex">
            {activeToken.chips.map((item, index) => index < 3 ?
              <div className="chip-container sm-margin">
                <img className={selectedChip == item.image ? `chip-${index} activeChip` : `chip-1`} id="medium-chip-image" src={item.image} onClick={element => selectChip(item.image)}></img>
                <div className="chip-centered med-text">{item.value}</div>
              </div> : null)}

            </div> 
            <div className="sm-flex">
              {activeToken.chips.map((item, index) => index >= 3 ?
                <div className="chip-container sm-margin">
                  <img className={selectedChip == item.image ? `chip-${index} activeChip` : `chip-1`} id="medium-chip-image" src={item.image} onClick={element => selectChip(item.image)}></img>
                  <div className="chip-centered med-text">{item.value}</div>
                </div> : null)}
            </div>
          </div>
          <div className="table-section">
            <Table ref={table} setBetInFull={setBetInFull} setBetText={setBetText} setBets={setBets} bets={bets} selectedChip={selectedChip} setCanUndo={setCanUndo} activeToken={activeToken}></Table>
          </div>
          <div className="small-chips">
            <div className="sm-flex">
              
            {activeToken.chips.map((item, index) => index < 3 ?
              <div className="chip-container sm-margin">
                <img className={selectedChip == item.image ? `chip-${index} activeChip` : `chip-1`} id="small-chip-image" src={item.image} onClick={element => selectChip(item.image)}></img>
                <div className="chip-centered small-text">{item.value}</div>
              </div> : null)}
              
            </div>
            <div className="sm-flex">
            {activeToken.chips.map((item, index) => index >= 3 ?
              <div className="chip-container sm-margin">
                <img className={selectedChip == item.image ? `chip-${index} activeChip` : `chip-1`} id="small-chip-image" src={item.image} onClick={element => selectChip(item.image)}></img>
                <div className="chip-centered small-text">{item.value}</div>
              </div> : null)}
            </div>
          </div>
          <div className="controls-section">
            <div className="spin-button">
              <div className="spin" onClick={bets.length == 0 && previousTotalBet > 0 ? repeatBet : spinWheel}>
                <img className="spin-image" src={spin}></img>
              </div>
              <div className="control-button" onClick={bets.length == 0 && previousTotalBet > 0 ? repeatBet : spinWheel}>{bets.length == 0 && previousTotalBet > 0? "Repeat Bet" : "Spin"}</div>
            </div>
            <div className={canUndo ?"control-button" : "disabled-control-button"} onClick={undo} >Undo</div>
            <div className="control-button" onClick={clearAll}>Clear All</div>
          </div>
          <div className="chips-section">
            {activeToken.chips.map((item, index) => 
              <div className="chip-container chip-margin">
                <img className={selectedChip == item.image ? `chip-${index} activeChip` : `chip-1`} id="large-chip-image" src={item.image} onClick={element => selectChip(item.image)}></img>
                <div className="chip-centered large-text">{item.value}</div>
              </div>)
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
